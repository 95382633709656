import { VisitCategory } from "../../types";
import {
  getOneTimeUseVisitReasonsFromBenefits,
  hasClaimedSkinCancerCheck,
} from "../../utils/misc";
import { NewVisitDrawerView, NewVisitSubmitValues } from "./View";
import { GlobalContext } from "@/components/GlobalContext";
import { Member } from "@/types/apiContract/member";
import {
  CreateVisitRequest,
  Visit,
  VisitReason,
} from "@/types/apiContract/visit";
import { handleApiError } from "@/utils/feedback";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { format, isBefore, isWithinInterval, parseISO } from "date-fns";
import { useContext, useState } from "react";

type Props = {
  onClose: () => void;
  onDateChange: (date: Date) => void;
  open: boolean;
  onComplete: () => Promise<void>;
  member: Member;
  visits: Visit[];
  visitReasons: VisitReason[];
  pickedDate: Date;
};
export const NewVisitDrawerContainer = (props: Props) => {
  const { member, visitReasons } = props;
  const { loggedInProvider } = useContext(GlobalContext);
  const [visitCount, setVisitCount] = useState(0);
  const [pickedDate, setPickedDate] = useState<Date>(new Date());
  // reset state after closing drawer
  const onClose = () => {
    setVisitCount((curr) => curr + 1);
    props.onClose();
  };

  const setPickedDateFunction = (date: Date) => {
    setPickedDate(date);
    props.onDateChange(date);
  };

  // Setup relevant member data
  const disabledVisitReasonTypes: VisitReason["type"][] =
    getOneTimeUseVisitReasonsFromBenefits(member);

  // convert array to array organized by category
  const visitCatsObj: {
    [key: string]: { reasons: VisitReason[] };
  } = {};
  visitReasons.forEach((reason) => {
    const cat = reason.category;
    if (!visitCatsObj[cat]) visitCatsObj[cat] = { reasons: [] };
    visitCatsObj[cat].reasons.push({ ...reason });
  });
  const visitCategories: VisitCategory[] = Object.entries(visitCatsObj).map(
    ([cat, value]) => ({
      name: cat as VisitReason["category"],
      reasons: value.reasons,
    })
  );

  // complete visit mutation
  const completeVisitMutation = useMutation({
    onError: handleApiError,
    onSuccess: async () => {
      await props.onComplete();
      onClose();
    },
    mutationFn: async (req: CreateVisitRequest) => {
      const res = await axios.post(
        `practices/${loggedInProvider.id}/members/${member.id}/visits`,
        req
      );
      return res;
    },
  });

  const onSubmit = async (values: NewVisitSubmitValues) => {
    const discountPercent = Number(values.discountPercent);
    const skinCareProductTotal = Number(values.skinCareProductTotal);
    const aestheticTotalPrice = Number(values.aestheticsServicesTotal);
    const newVisit: CreateVisitRequest = {
      claimed: Boolean(values.isUsingAvailableCredit),
      discountApplied: Boolean(values.isDiscountApplied),
      visitInfo: values.selectedReasons.map((reason) => {
        return {
          reason: reason.type,
          ...(reason.detail && { detail: reason.detail }),
        };
      }),
      ...(aestheticTotalPrice > 0 && {
        aestheticTotalPrice: aestheticTotalPrice,
      }),
      ...(skinCareProductTotal > 0 && {
        skinCareProductTotalPrice: skinCareProductTotal,
      }),
      ...(discountPercent > 0 && {
        skinCareProductsDiscount: discountPercent,
      }),
      ...(values.skipCreditReason && {
        notClaimedReason: values.skipCreditReason,
      }),
      ...(values.skipDiscountReason && {
        discountNotAppliedReason: values.skipDiscountReason,
      }),
      providers: values.selectedProviders,
      visitDate: format(new Date(values.visitDate), "yyyy-MM-dd"),
    };
    await completeVisitMutation.mutateAsync(newVisit);
  };

  let todaysCreditInfo = member.consumerClaimHistory.creditInfo.find((c) => {
    const availableFrom = parseISO(c.availableFrom);
    const availableTill = parseISO(c.availableTill);
    return isWithinInterval(pickedDate, {
      start: availableFrom,
      end: availableTill,
    });
  });
  if (todaysCreditInfo === undefined) {
    const credInfo = member.consumerClaimHistory.creditInfo.find((c) => {
      return c.order === 1;
    });
    if (isBefore(pickedDate, parseISO(credInfo!!.availableFrom))) {
      todaysCreditInfo = credInfo;
    }
  }

  const availableCredit = todaysCreditInfo?.totalCreditAmount || 0;
  const createdAt = parseISO(member.createdAt);
  return (
    <NewVisitDrawerView
      key={visitCount}
      open={props.open}
      onClose={onClose}
      onDateChange={setPickedDateFunction}
      memberCreatedAt={createdAt}
      visitCategories={visitCategories}
      onSubmit={onSubmit}
      hasDoneSkinCancerScreening={hasClaimedSkinCancerCheck(member)}
      availableCredit={availableCredit}
      disabledVisitReasonTypes={disabledVisitReasonTypes}
    />
  );
};
