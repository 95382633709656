import { snackIt } from "../SnackbarManager";
import { LoadingScreen } from "./components/LoadingScreen";
import { useRememberMeLogout } from "./hooks/useRememberMeLogout";
import { fetchAuthSession, signOut } from "./utils/auth";
import { Practice } from "@/types/apiContract/practice";
import { GetSurveysResponse, Survey } from "@/types/apiContract/survey";
import { useQueries } from "@tanstack/react-query";
import { getCurrentUser } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import axios from "axios";
import { PropsWithChildren, createContext, useEffect, useState } from "react";

type User = {
  providerId: string;
  email: string;
  roles: ("ProviderAdmin" | "Provider")[];
};

// NOTE: Practice used to be called Provider (this is why this is called LoggedInProvider and not LoggedInPractice)
export type LoggedInProvider = Practice & {
  email: string;
  roles: User["roles"];
  survey?: Survey;
};

type GlobalContextType = {
  loggedInProvider: LoggedInProvider; // technically loggedInProvider could come out undefined (but as long as its under <AuthenticatedRoute /> it wont be), but we'll say the type cant be undefined this way wont have to worry about checking undefined loggedInProvider in components using useContext(GlobalContext)
  refetchProvider: (params: { includeSurvey: boolean }) => Promise<void>;
};

// global context for the entire app (ie: auth)
// starts as empty since it will be initialized with hooks below
export const GlobalContext = createContext<GlobalContextType>(
  {} as GlobalContextType
);

const GlobalContextContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState<User>();
  const [loading, setLoading] = useState(true);
  const [hasErrorLoadingUser, setHasErrorLoadingUser] = useState(false);

  // handle "remember me" logout (if "remember me" is unchecked, logout user after a certain amount of time)
  useRememberMeLogout({
    onSessionExpire: () => {
      snackIt.default({
        message: "Session has ended, logging out...",
        severity: "warning",
      });
      setTimeout(() => {
        signOut();
      }, 2000);
    },
  });

  const loadUser = async () => {
    // check if user is logged in
    try {
      await getCurrentUser(); // if no user is logged in, this will throw an error
    } catch (e) {
      setLoggedInUser(undefined);
      setLoading(false);
      return;
    }

    // user is logged in, set token for axios (making authed api calls)
    const { tokens } = await fetchAuthSession();
    const bearerToken = tokens.accessToken.toString();
    axios.defaults.headers.common["Authorization"] = `Bearer ${bearerToken}`;

    try {
      const providerId =
        tokens.idToken?.payload["custom:account_id"]?.toString();
      const email = tokens.idToken?.payload["email"]?.toString();
      const roles = tokens.idToken?.payload["cognito:groups"] as User["roles"];

      if (!providerId) {
        throw Error("expected account id (provider id) from token");
      }
      if (!email) {
        throw Error("expected account email from token");
      }
      if (!roles || !Array.isArray(roles)) {
        throw Error("expected array of roles from token");
      }

      setLoggedInUser({ providerId, email, roles });
      setLoading(false);
    } catch (e: any) {
      setHasErrorLoadingUser(true);
      setLoading(false);
      throw Error(e);
    }
  };

  // handle auth events (sign in, sign out)
  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          (async () => {
            await loadUser();
          })();
          break;
        case "signedOut":
          window.localStorage.clear(); // ensure custom localstorage items are cleared (ie: REMEMBER_ME_EXPIRY_TIME_LOCAL_STORAGE_KEY)
          window.location.reload(); // ensure app is fully reset (in case a useQuery that uses loggedInProvider.id is used without loggedInProvider.id in its keys, ie: it doesnt refetch when it changes)
          break;
      }
    });
    return unsubscribe;
  }, []);

  // load user on app start
  useEffect(() => {
    loadUser();
  }, []);

  // get user's provider & survey data
  const [providerQuery, surveyQuery] = useQueries({
    queries: [
      {
        queryKey: ["practices", loggedInUser?.providerId],
        queryFn: () => axios.get(`/practices/${loggedInUser?.providerId}`),
        enabled: Boolean(loggedInUser?.providerId),
        throwOnError: true, // will trigger react error boundary
      },
      {
        queryKey: ["surveys"],
        queryFn: () => axios.get<GetSurveysResponse>(`/legacy/api/v1/surveys`),
        enabled: Boolean(loggedInUser?.providerId),
        throwOnError: true,
      },
    ],
  });

  const refetchProvider = async (params: { includeSurvey: boolean }) => {
    await providerQuery.refetch();
    if (params.includeSurvey) {
      await surveyQuery.refetch();
    }
  };

  // handle provider data
  if (loading || providerQuery.isLoading || surveyQuery.isLoading) {
    return <LoadingScreen />;
  }

  if (hasErrorLoadingUser) {
    throw Error("error loading user");
  }

  const loggedInProvider = providerQuery.data?.data;
  if (loggedInUser && !loggedInProvider) {
    throw Error("found user, but could not find provider");
  }

  // handle survey data
  if (loggedInProvider && surveyQuery.data?.data.results) {
    loggedInProvider["survey"] = surveyQuery.data?.data.results[0]; // only taking in the first survey
  }

  // put in account details
  if (loggedInProvider && loggedInUser) {
    loggedInProvider["email"] = loggedInUser.email;
    loggedInProvider["roles"] = loggedInUser.roles;
  }

  return (
    <GlobalContext.Provider value={{ loggedInProvider, refetchProvider }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextContainer;
